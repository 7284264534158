<template>
  <v-card :loading="loading" :disabled="loading" class="mt-n10 moholand-shadow">
    <v-card-title>
      {{ 'ویرایش "' + title + '"' }}
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />

        <v-row align="center" justify="end" class="mb-3">
          <v-btn
            class="mx-4"
            dark
            color="success"
            type="submit"
            :loading="loading"
          >
            ویرایش انیمیشن
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MySnackbar from "@/components/MySnackbar";
import VFormBase from "vuetify-form-base";
import { getErrorText } from "@/constants/GlobalConstants";
import { formPostPrepare, formGetPrepare } from "@/constants/PanelFormHandler";

export default {
  components: {
    VFormBase,
    MySnackbar,
  },
  created() {
    this.getData();
    this.getSellers();
  },

  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      title: "",
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("edit") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      model: {
        title: "",
        desc: "",
        user_id: "",
        media_id: "",
      },
      schema: {
        title: {
          type: "CustomInput",
          label: "نام انیمیشن",
          rules: [Constants.rules.required],
          required: true,
        },
        desc: {
          type: "CustomInput",
          label: "توضیحات",
          required: true,
        },
        user_id: {
          type: "CustomInput",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "value",
          label: "فروشنده",
          rules: [Constants.rules.required],
          loading: true,
          required: true,
        },
        media_id: {
          type: "MyFilePond",
          title: "فایل",
          files: "",
          rules: [Constants.rules.required],
          required: true,
          fileTypes: "video/mp4",
          maxFileSize: "300MB",
        },
      },
    };
  },

  methods: {
    getData() {
      this.loading = true;
      MyAxios.get("/portfolios/index/" + this.id, {
        params: {
          noPaginate: true,
          with: "media",
        },
      })
        .then((response) => {
          console.log(response.data);
          this.title = response.data[0].title;
          formGetPrepare(response, this.model);
          this.schema.media_id.files = response.data[0].media;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getSellers() {
      this.loading = true;
      MyAxios.get("/user/list/vendor", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          this.schema.user_id = {
            ...this.schema.user_id,
            loading: false,
            items: response.data.map((val) => ({
              id: val.id,
              value: val.first_name + " " + val.last_name,
            })),
          };
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = formPostPrepare(this.model);
        MyAxios.post(`portfolios/${this.id}/update`, formData)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "انیمیشن با موفقیت ویرایش شد",
              type: "success",
            });
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: getErrorText(error),
            });
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
  },
};
</script>

<style></style>
